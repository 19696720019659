
#InstagramStoriesLivestream.template {
  height: 1920px;
  width: 1080px;
}

#InstagramStoriesLivestream .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 96px;
  height: 1920px;
  width: 1080px;
  position: relative;
}


#InstagramStoriesLivestream  .title {
  color: #ffffff;
  font-size: 120px;
  font-weight: 600;
}



#InstagramStoriesLivestream  .title-wrap {
  /*margin-bottom: 316px;*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


#InstagramStoriesLivestream  .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 48px;
  width: 100%;
}


#InstagramStoriesLivestream header {
  display: flex;
  font-size: 48px;
  align-items: center;
  margin-top: 170px;
}


#InstagramStoriesLivestream .logo {
  margin-right: 20px;
  width: 335px;
}

#InstagramStoriesLivestream .logo.ide {
  width: 454px;
}


#InstagramStoriesLivestream .product-name {
  font-weight: 600;
  min-width: 500px;
}

#InstagramStoriesLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#InstagramStoriesLivestream .upload-wrap {
  position: absolute;
  right: 0;
  height: 1920px;
  bottom: 0;
  border-radius: 20px 0 0;
  z-index: 1;
  /*overflow: hidden;*/
  width: 1080px;

}


#InstagramStoriesLivestream .icon-wrap {
  z-index: 1;
}

#InstagramStoriesLivestream .badge {
  background-color: #000;
  border-radius: 30px;
  color: #fff;
  display: block;
  z-index: 1;
  font-size: 48px;
  font-weight: 600;
  height: 63px;
  line-height: 45px;
  padding: 4px 36px;
  white-space: nowrap;
  margin-top: 120px;
  margin-bottom: 50px;
}

#InstagramStoriesLivestream .date {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 500px;
}


.EDU #InstagramStoriesLivestream .logo {
  width: 570px;
  margin-top: -2px;
}

.EDU #InstagramStoriesLivestream .badge {
  margin-top: 135px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
  padding-right: 20px;
  height: unset;
}
.EDU #InstagramStoriesLivestream .badge .textarea-wrap {
  margin-top: -5px;
}

.EDU #InstagramStoriesLivestream .logo {
  width: 570px;
  margin-top: -2px;
}

.EDU #InstagramStoriesLivestream .title {
  margin-top: 8px;

}


.EDU #InstagramStoriesLivestream .dropzone {
  position: absolute;
  right: 20px;
  bottom: 80px;
}