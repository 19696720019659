#BlogFeaturedLivestream.template {
  height: calc(720px * 2);
  width: calc(1280px * 2);
  /*zoom: calc(100% / 4);*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#BlogFeaturedLivestream .content {
  background-position: 0 calc(-500px * 2);
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(48px * 2) calc(48px * 2) calc(90px * 2) calc(48px * 2);
  height: calc(720px * 2);
  width: 100%;
  position: relative;
  height: calc(500px * 2);
}
#BlogFeaturedLivestream .title {
  color: #ffffff;
  font-weight: 500;
  width: calc(630px * 2);
  font-size: calc(80px * 2);
}
#BlogFeaturedLivestream .name {
  color: #ffffff;
  width: calc(630px * 2);
  font-size: calc(40px * 2);
  font-weight: 600;
}
#BlogFeaturedLivestream header {
  display: flex;
  font-size: calc(48px * 2);
  align-items: center;
}
#BlogFeaturedLivestream .logo {
  margin-right: calc(20px * 2);
  width: calc(335px * 2);
}
#BlogFeaturedLivestream .logo.ide {
  width: calc(378px * 2);
}
#BlogFeaturedLivestream .product-name {
  font-weight: 600;
  /*min-width: 500px;*/
  /*margin-top: 10px;*/
  font-size: calc(40px * 2);
}
#BlogFeaturedLivestream .product-name::before {
  content: "|";
  margin: calc(0px * 2) calc(14px * 2) calc(0px * 2) calc(0px * 2);
  position: relative;
}
#BlogFeaturedLivestream .icon-wrap {
  position: absolute;
  right: calc(71px * 2);
  bottom: calc(58px * 2);
  z-index: 1;
}
#BlogFeaturedLivestream .upload-wrap {
  position: absolute;
  padding-top: calc(65px * 2);
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  border-radius: calc(20px * 2) 0 0;
  height: 100%;
}
#BlogFeaturedLivestream .upload-wrap .image-upload {
  border-radius: calc(20px * 2) 0 0;
}


.EDU #BlogFeaturedLivestream .content {
  padding-left: calc(70px * 2);
  padding-right: calc(70px * 2);
  height: calc(568px* 2);
}