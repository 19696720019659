
#YouTubeCommunityLivestream .product-name {
  font-weight: 600;
  min-width: 500px;
  margin-top: 15px;
  color: #ffffff;
  font-size: 48px;

}


#YouTubeCommunityLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}



#YouTubeCommunityLivestream.template {

  height: 1080px;
  width: 1080px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #0078d4, #fe4a49);
}

#YouTubeCommunityLivestream .content {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 1080px;
  width: 1080px;
  align-items: flex-start;
  padding: 72px;
}

#YouTubeCommunityLivestream .badge {
  background-color: #000;
  border-radius: 30px;
  color: #fff;
  display: block;
  font-size: 40px;
  font-weight: 600;
  height: 45px;
  line-height: 45px;
  margin-top: 0;
  order: 3;
  padding: 3px 36px;
  position: absolute;
  right: 40px;
  white-space: nowrap;
  z-index: 10;
}


#YouTubeCommunityLivestream header {
  display: flex;
  /*align-items: center;*/
  width: 100%;
  font-size: 48px;
}



#YouTubeCommunityLivestream .logo {
  margin-right: 20px;
  display: flex;
  align-items: center;
  width: 335px;
}



#YouTubeCommunityLivestream .logo.ide {
  width: 454px;
}


#YouTubeCommunityLivestream .date {
  margin-bottom: 54px;
}

#YouTubeCommunityLivestream .name,
#YouTubeCommunityLivestream .date {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

#YouTubeCommunityLivestream .position {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /*opacity: 0.4;*/
  margin-bottom: 40px;
}

#YouTubeCommunityLivestream .title {
  font-weight: 600;
  color: #fff;
  font-size: 88px;
  line-height: 88px;
  height: 440px;
  width: 700px;
  margin-bottom: 70px;
}




#YouTubeCommunityLivestream .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1 ;
  display: flex;
  border-radius: 20px 0 0;
  height: 810px;
}


#YouTubeCommunityLivestream .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}


.EDU #YouTubeCommunityLivestream .logo {
  width: 570px;
  margin-top: -2px;
}

.EDU #YouTubeCommunityLivestream .badge {
  margin-top: 10px;
  right: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 49px;
  padding-right: 30px;
  height: unset;
}
.EDU #YouTubeCommunityLivestream .badge .textarea-wrap {
  margin-top: -5px;
}

.EDU #YouTubeCommunityLivestream .logo {
  width: 570px;
  margin-top: -2px;
}

.EDU #YouTubeCommunityLivestream .title {
  font-size: 80px;
  width: 720px;
  margin-top: -20px;
  height: 470px;
}

.EDU #YouTubeCommunityLivestream.template {
  background-color: white !important;
  background: unset;
}