#EmailPhotoLivestream.template {
  height: calc(144px * 2);
  width: calc(650px * 2);
  display: flex;
  flex-direction: column;
  background-color: #191919;
  position: relative;
}
#EmailPhotoLivestream .bg {
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}
#EmailPhotoLivestream .content::after {
  content: '';
  height: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: calc(650px * 2);
  background-color: #fff;
}
#EmailPhotoLivestream .content {
  background-position: 0 calc(-500px * 2);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  height: calc(144px * 2);
}
#EmailPhotoLivestream .title {
  color: #ffffff;
  font-weight: 600;
  font-size: calc(43px * 2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#EmailPhotoLivestream .name {
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(43px * 2);
  font-weight: 400;
  align-items: center;
}

#EmailPhotoLivestream .textarea {
  text-align: center;
}

#EmailPhotoLivestream .upload-wrap {
  /*position: absolute;*/
  border-radius: calc(20px* 2) 0 0;
  height: calc(144px * 2);
  width: 100%;
}

#EmailPhotoLivestream  .dropzone {
width: 240px;
  height: 240px;
}