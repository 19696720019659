
.icon-button {
  width: 30px;
  height: 30px;
  background-size: 60%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0;
  display: block;
  background-color: transparent;
}

.icon-button.selected {
  outline: 1px solid #177DFE;
}

.icon-selector {
  display: grid;
  padding: 9px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 10px;
  column-gap: 10px;
  margin-bottom: 30px;
  border-radius: 6px;
  border: 1px solid #646464;
  background-color: #27282C;
}
