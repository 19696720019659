.dropzone {
    margin-left: 40px;
    margin-right: 40px;
    background-color: transparent;
    border: 2px dashed #fff;
    border-radius: 50%;
    cursor: pointer;
    font-family: inherit;
    font-size: calc(16px * var(--zoom-level));
    color: #fff;
    /*height: 70%;*/
    z-index: 11;
    width: 50%;
    aspect-ratio : 1 / 1;
    opacity: 0.7;
}

.dropzone:hover {
    opacity: 1;
}


.button-pack {
    flex-direction: column;
    display: none;
}

.button-pack.visible {
    display: flex;
}

.image-upload-outer {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    height: 100%;
    min-width: 200px;
    min-height: 200px;
    width: 100%;
    aspect-ratio : 1 / 1;
    overflow: hidden;
    /*cursor: grab;*/
}

.image-upload-outer.hidden {
    display: none;
    z-index: -1;
}

.image-upload {
    z-index: 1;
    height: 100%;
    /*min-width: 200px;*/
    /*min-height: 200px;*/
    width: 100%;
    /*aspect-ratio : 1 / 1;*/
    /*right: 0;*/
    /*top: 0;*/
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    /*padding-left: 100px;*/
}

.picture {

    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-upload .tools {
    z-index: 1000;

    position: absolute;
    top: 0;

    right: calc(-100px * var(--zoom-level));
    display: grid;
    grid-template-rows: repeat(3, max-content) auto;
    /*grid-row-gap: 3%;*/
    align-items: flex-end;

    visibility: hidden;
}

.template-wrap:hover .tools {
    visibility: visible;
}

.image-upload  .image-wrapper {
    width: 100% !important;
    height: 100% !important;
    pointer-events: none;
    cursor: default;
}

.image-upload  .image-wrapper.active {
    pointer-events: auto;
    cursor: grab;
}

.image-upload .round-btn {
    zoom: var(--zoom-level);
    margin-bottom: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: rgba(0,0,0,.4);
    backdrop-filter: blur(16px);
    cursor: pointer;
    z-index: 1;
    color: #fff;
    font-size: 32px;
    line-height: 1;
    text-align: center;
    font-family: "arial",sans-serif !important;
}

.image-upload .round-btn.expander {
    opacity: 1;
}

.image-upload  .round-btn.expander.opaque {
    opacity: 0.5;
}