.templates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 370px);
  /*min-width: 700px;*/
  padding: 24px;
  position: fixed;
  overflow: scroll;
  height: 100vh;
}

.templates-inner {
  position: relative;
  /*width: 100%;*/
} 


.tag {
  /*position: absolute;*/
  right: 370px;
  border-radius: 4px;
  color: #a9a9a9;
  display: block;
  font-size: 13px;
  padding: 5px;
  position: fixed;
  top: 20px;
  margin-right: 20px;
  z-index: 20;
}

.tag-latest {
  background-color: #006400;
}

.tag-soon {
  background-color: darkred;
}

.tag-outdated {
  color: #fff;
  background-color: #d8a700;
}