.textarea {
  overflow: hidden;
  resize: none;
  background-color: transparent;
  border: none;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  width: auto;
  box-sizing: border-box;
  /*min-height: 1em;*/
  display: block;
  line-height: 1;
  text-align: inherit;
  z-index: 12;
  min-width: 1em;
  overflow: hidden;
  margin: -0.1em 0 0 0;
  transform: translateY(0.1em);
}

.textarea:hover {
  outline: 1px dashed #ccc;
}

.textarea:focus {
  outline: 1px dashed #ccc;
}

.textarea-wrap {
  display: flex;
  position: relative;
  width: fit-content;
}

.input-sizer {
  display: inline-grid;
  vertical-align: top;
  position: relative;
  align-items: stretch;
  box-sizing: border-box;
}

.input-sizer::after, .textarea {
  font: inherit;
  /*margin: 0;*/
  resize: none;
  background: none;
  appearance: none;
  width: auto;
  /*min-width: 1em;*/
  grid-area: 1 / 2;
  border: none;
}

.input-sizer::after {
  content: attr(data-value) ' ';
  visibility: hidden;
  white-space: pre-wrap;
}

.textarea.font-kr {
  font-family: "sourcehan kr", sans-serif !important;
}

.textarea.font-jp {
  font-family: "sourcehan jp", sans-serif;
}

.textarea.font-ch {
  font-family: "sourcehan cn", sans-serif !important;
}

/* Hide Grammarly and other extension overlays */
grammarly-extension,
.grammarly-inline-card,
.grammarly-desktop-integration,
div[data-grammarly-part="button"],
grammarly-popups,
.grammarly-card {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

/* Prevent any extension overlays */
.textarea-wrap {
  isolation: isolate;
}

.textarea,
.textarea:hover,
.textarea:focus {
  position: relative;
  z-index: 1;
}
