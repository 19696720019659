* {
  box-sizing: border-box;
  /*letter-spacing: -0.01em !important;*/
  font-feature-settings: "kern", "liga", "calt";
  /*font-family: "Inter", sans-serif !important;*/
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

:root {
  --upload-icon: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/UI/Icons/upload.svg);
  --link-icon: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/UI/Icons/link.svg);
  --download-icon: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/UI/Icons/download.svg);

  --upload-icon-w: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/UI/Icons/upload-white.svg);
  --link-icon-w: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/UI/Icons/link-white.svg);
  --download-icon-w: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/UI/Icons/download-white.svg);
  --date-input-border-radius: 9px;
  --date-input-font-size: 32px;
  --date-input-margin-bottom: 14px;
  --date-input-padding: 6px 15px;

  --jb: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/Icons/jb.svg);
  --dn: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/Icons/dotnet.svg);
  --tc: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/Icons/tc.svg);
  --java: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/Icons/java.svg);
  --mp: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/Icons/mp.svg);
  --ps: url(https://d3r50642m20jlp.cloudfront.net/kraken/tiles/Neurobaker/Icons/php.svg);
}



body {
  background-color: #222;
  font-family: "JetBrains Sans", Helvetica, Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  min-width: 1200px;
}

main {
  display: flex;
  flex-direction: row;
  /*padding: 80px 40px;*/
  justify-content: space-between;
  align-items: flex-start;
  min-width: 1024px;
}

.templates .label, aside .label {
  color: #727272;
  font-size: 16px;
  display: block;
  font-weight: 500
}


.mask, .shape {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
}

.shape {
  z-index: 0;
}

.template {
  position: relative;
  zoom: calc(1/var(--zoom-level));
    background-color: #000;
}

.template.nobg {
  background-color: transparent;
}


.template-wrap {
  margin-bottom: 50px;
  position: relative;
  padding-right: 150px;
  width: fit-content;

}

.template-header {
  z-index: 2;
}

.textarea-wrap {
  z-index: 2;
}

.template-wrap.hidden .template {
  position: relative;
  pointer-events: none;
  height: 100px !important;
  overflow: hidden;
}

.template-wrap.hidden .content {
  opacity: 0;
}

.template-wrap.hidden .template::after {
  content: "Hidden for export";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #727272;
  /*font-size: calc(16px * 2);*/
  font-size: calc(16px * var(--zoom-level));
  /*padding: 7px;*/
  width: 100%;
  height: 100%;
  background-color: #383838;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.template-wrap.hidden .template .tools {
  display: none;
}



.logo img {
  width: 100%;
}

.upload-wrap.collapsed {
  display: none !important;
}

.blacktext .template * {
  color: #000 !important;
}

.productlogo .product-name{
    display: none;
}

.whitebg .template {
    background-color: white !important;
}

.graybg .template {
  background-color: #f2f5fe !important;
}

.blackproduct .product-name{
  color: #000 !important;
}
.bottomlogoinvers #YouTubeCommunity .product-name{
  color: #fff;
}

.bottomlogoinvers #InstagramStories .product-name{
  color: #fff;
}

/* Background toolbar active states */
[class*="bg-"] .bg-selector {
  position: relative;
}

[class*="bg-"] .bg-selector::before {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #6858f6;
}
