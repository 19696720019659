
#BlogSocialShare.template {
  height: calc(2 * 720px);
  width: calc(2 * 1280px);
}

#BlogSocialShare .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 160px 195px 280px;
  height: calc(2 * 720px);
  width: 100%;
}

#BlogSocialShare.layout-5 .content {
  width: 50%;
  padding-right: 20px;
}

#BlogSocialShare.layout-6 .content {
  width: 70%;
  padding-right: 20px;
}

#BlogSocialShare.layout-7 .content {
  width: 80%;
  padding-right: 20px;
}

#BlogSocialShare  .title {
  color: #ffffff;
  font-weight: 600;
}

#BlogSocialShare  .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 80px;
  line-height: 92px;
  width: 100%;
}

#BlogSocialShare.layout-1 .title {
  font-size: 192px;
}

#BlogSocialShare.layout-2  .title{
  font-size: 144px;
}

#BlogSocialShare.layout-3 .title {
  font-size: 192px;
  margin-bottom: 50px;
}

#BlogSocialShare.layout-4 .title {
  font-size: 144px;
  margin-bottom: 50px;
}

#BlogSocialShare.layout-5 .title {
  font-size: 144px;
}

#BlogSocialShare.layout-6 .title {
  font-size: 144px;
}

#BlogSocialShare.layout-7 .title {
  font-size: 192px;
}

#BlogSocialShare header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#BlogSocialShare.layout-5 header,
#BlogSocialShare.layout-6 header {
  flex-direction: column;
  align-items: flex-start;
}

#BlogSocialShare .logo {
  margin-right: 20px;
  width: 674px;
}

#BlogSocialShare .logo.ide {
  width: 910px;
}


.EDU #BlogSocialShare .logo {
    width: 910px;
}


#BlogSocialShare .product-name {
  font-weight: 600;
  min-width: 500px;
  font-size: 96px;
  margin-top: 12px;
}

#BlogSocialShare .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#BlogSocialShare.layout-5 .product-name::before,
#BlogSocialShare.layout-6 .product-name::before {
  content: "";
  margin: 0 0 0 170px;
  position: relative;
}

#BlogSocialShare .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#BlogSocialShare .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1 ;
  display: flex;
  height: 100%;
}

#BlogSocialShare.layout-5 .upload-wrap {
  width: 50%;
}

#BlogSocialShare.layout-6 .upload-wrap {
  width: calc(1/3*100%);
}

#BlogSocialShare .upload-wrap .image-upload {
  border-radius: 40px 0 0;
}

#BlogSocialShare .title-wrap {
}

/*Products fixes*/
.DN #BlogSocialShare .product-name::before {
    display: none;
}

#BlogSocialShare.newsletter .title{
  font-size: 192px;
  /*line-height: 220px;*/
}

#BlogSocialShare.newsletter .date {
  border: 1px solid #fff;
  border-radius: calc(2 *var(--date-input-border-radius));
  color: #fff;
  display: inline-block;
  font-size: calc(var(--date-input-font-size) * 2);
  font-weight: 400;
  margin-bottom:  var(--date-input-margin-bottom);
  padding-top: 28px;
  padding-bottom: 12px;
  padding-left: 26px;
  padding-right: 12px;
  width: auto;
  z-index: 100;
  margin-top: 54px;
  position: relative;
}

#BlogSocialShare.newsletter::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.JB #BlogSocialShare.newsletter::after {
  background-image: var(--jb);
  height: 800px;
  right: -108px;
  top: 460px;
  width: 978px;
  z-index: 3;
}

.JB #BlogSocialShare.newsletter .title-wrap {
  margin-top: 298px;
  position: unset;

}
  /* Hide JetBrains logo in Instagram and YouTube */
.JB #InstagramStories .logo,
.JB #YouTubeCommunity .logo {
  display: none;
}



.JB #BlogSocialShare .logo {
  margin-right: 20px;
  width: 669px;
}

.IJ #BlogSocialShare.newsletter .product-name
{
  display: none;
}
.IJ #BlogSocialShare.newsletter::after {
  background-image: var(--java);
  left: 1591px;
  top: 204px;
  width: 745px;
  height: 942px;
  z-index: 3;
}

.DN #BlogSocialShare.newsletter .product-name {
  display: none;
}
.DN #BlogSocialShare.newsletter::after {
  background-image: var(--dn);
  left: calc( 2 * 790px);
  top: calc( 2 * 194px);
  width: calc( 2 * 356px);
  height: calc( 2 * 288px);
  z-index: 3;
}


/*MP*/

.MP #BlogSocialShare.newsletter .product-name {
  display: none;
}
.MP #BlogSocialShare.newsletter::after {
  background-image: var(--mp);
  width: calc(2 * 370px);
  height: calc(2 * 500px);
  left: calc(2 * 823px);
  top: calc(2 * 214px);
  z-index: 3;
}

.MP #BlogSocialShare.newsletter .textarea-wrap {
  margin-top: -22px;

}

/*PS*/

.PS #BlogSocialShare.newsletter .product-name {
  display: none;
}
.PS #BlogSocialShare.newsletter::after {
  background-image: var(--ps);
  left: calc(2* 719px);
  top: calc(2* 153px);
  width: calc(2* 457px);
  height: calc(2* 337px);
  z-index: 3;
}

/*TC*/

.TC #BlogSocialShare.newsletter .product-name {
  display: none;
}
.TC #BlogSocialShare.newsletter::after {
  background-image: var(--tc);
  width: calc(2 * 389px);
  height: calc(2 * 489px);
  left: calc(2 * 794px);
  top: calc(2 * 299px);
  z-index: 3;
}

