aside {
  width: 370px;
  background-color: #1B1B1B;
  /*border: 1px solid #646464;*/
  box-sizing: border-box;
  height: 100vh;
  padding: 20px;
  position: fixed;
  right: 0;
  z-index: 2;
  overflow: scroll;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.toolbar-row {
  margin-bottom: 30px;
}

.toolbar-inner .label {
  margin-bottom: 7px;
}

aside [data-test="select"] {
  margin-bottom: 32px;
  border-radius: 6px;
  border: 1px solid #646464;
  background-color: #27282C;
}

aside [data-test="select-content"] {
  padding: 6px 0;
  color: #DFDFDF !important;
  font-size: 14px;
  font-weight: 500 !important;
}

.toolbar-btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

#save_button {
  min-width: 117px;
}

aside .btn {
  font-size: 14px;
  border-radius: 6px;
  color: #B5B5B5 !important;
  width: 150px;
  font-weight: 500;
  transition: background-color .2s ease-in-out,
  color .2s ease-in-out,
  border .2s ease-in-out;
}

aside .btn:hover,
aside .btn:focus {
  background-color: #177DFE !important;
  border: 1px solid #177DFE !important;
  color: #fff!important;
}

aside .btn span {
  padding-left: 17px;
  position: relative;
}
#save_button  span::before {
  background-image: var(--link-icon);
}

#export_button  span::before {
  background-image: var(--download-icon);
}

#save_button:hover  span::before,
#save_button:focus  span::before {
  background-image: var(--link-icon-w);
}

#export_button:hover  span::before,
#export_button:focus  span::before{
  background-image: var(--download-icon-w);
}

aside .btn span::before {
  content: '';
  top: 0;
  left: 0;
  margin-left: -7px;
  width: 17px;
  height: 14px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  position: absolute;
  transition: background-image .2s ease-in-out;
}


.localization {
  display: flex;
  align-items: center;
}

.localization .label {
  margin-bottom: 0;
  margin-right: 23px;
}

.localization-wrap {
  margin-bottom: 30px;
}


.btn .loader {
  position: absolute;
  top: 6px;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 24px;
  border: 2px solid rgb(181, 181, 181);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

#export_button:hover .loader,
#export_button:focus .loader {
  border-color: #fff #fff transparent #fff;
}

footer {
  margin-top: 80px;
}

.footer-link-wrapper {
  display: flex;
  align-items: center;
}
.footer-link {
  margin-left: 10px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}