#Email02.template {
  height: calc(2 * 200px);
  width: calc(2 * 650px);
  display: flex;
  align-items: stretch;
  justify-content: center;
}

#Email02.layout-3.template {
  position: relative;
}

#Email02 .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 165px 96px;
}

#Email02 .textarea {
  text-align: center;
}

#Email02 .title {
  color: #ffffff;
  font-weight: 600;
  font-size: 86px;
}

#Email02 .title .textarea {
  /*line-height: 98px;*/
  line-height: 1;
}
#Email02.layout-3 .content {
  padding: 110px 96px;
}

#Email02 .upload-wrap {
  position: absolute;
  padding-top: 130px;
  bottom: 0;
  right: 0;
  z-index: 1 ;
  display: flex;
  height: 100%;
}

#Email02.layout-3 .title-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: auto;
}

#Email02.layout-3 .badge {
  background: #fff;
  font-size: 36px;
  font-weight: 600;
  color: #000;
  padding: 24px 64px;
  border-radius: 48px;
  z-index: 1;
  margin-top: auto;
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
}

#Email02.layout-3 .badge .textarea {
  line-height: 48px;
}