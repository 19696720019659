
#StreamYardLivestream.template {
  height: 1080px;
  width: 1920px;
}

#StreamYardLivestream .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 72px;
  height: 1080px;
  width: 100%;
  position: relative;
}

#StreamYardLivestream .title {
  background-color: #000 !important;
  background: linear-gradient(90deg, #0047fd, #000);
  color: #fff;
  content: "Livestream";
  display: block;
  order: 3;
  right: 0;
  z-index: 10;
  /*width: fit-content;*/

  border-bottom-left-radius: 20px;
  font-size: 25px;
  font-weight: 600;
  height: 38px;
  left: auto;
  line-height: 28px;
  margin: 0;
  min-width: 200px;
  padding: 3px 24px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: max-content;
}