
#InstagramCountdownLivestream.template {
  height: 1920px;
  width: 1080px;
}

#InstagramCountdownLivestream .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 96px;
  height: 1920px;
  width: 1080px;
  position: relative;
}


#InstagramCountdownLivestream  .title {
  color: #ffffff;
  font-size: 120px;
  font-weight: 600;
}



#InstagramCountdownLivestream  .title-wrap {
  /*margin-bottom: 316px;*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


#InstagramCountdownLivestream  .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 48px;
  width: 100%;
}


#InstagramCountdownLivestream header {
  display: flex;
  font-size: 48px;
  align-items: center;
  margin-top: 170px;
}


#InstagramCountdownLivestream .logo {
  margin-right: 20px;
  width: 335px;
}

#InstagramCountdownLivestream .logo.ide {
  width: 454px;
}


#InstagramCountdownLivestream .product-name {
  font-weight: 600;
  min-width: 500px;
}

#InstagramCountdownLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#InstagramCountdownLivestream .upload-wrap {
  position: absolute;
  right: 0;
  height: 1920px;
  bottom: 0;
  border-radius: 20px 0 0;
  z-index: 1;
  /*overflow: hidden;*/
  width: 1080px;

}


#InstagramCountdownLivestream .icon-wrap {
  z-index: 1;
}
#InstagramCountdownLivestream .name {
  margin-top: 240px;
}

#InstagramCountdownLivestream .name,
#InstagramCountdownLivestream .position {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
}

#InstagramCountdownLivestream .position {
  font-weight: 400;
}


.EDU #InstagramCountdownLivestream .dropzone {
  position: absolute;
  right: 20px;
  bottom: 80px;
}