
#BlogFeatured.template {
  height: calc(2 * 720px);
  width: calc(2 * 1280px);
}

#BlogFeatured .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 193px 144px 160px;
  height: calc(2 * 720px);
  width: 100%;
}

#BlogFeatured.layout-5 .content {
  width: 50%;
  padding-right: 0;
  padding-top: 132px;
}

#BlogFeatured.layout-6 .content {
  width: calc(2/3*100%);
  padding-top: 132px;
  padding-right: 0;
}

#BlogFeatured.layout-7 .content {
  width: 80%;
  padding-right: 20px;
}

#BlogFeatured .title {
  color: #ffffff;
  font-weight: 600;
  font-size: 160px;
  line-height: 192px;
}


#BlogFeatured .title-wrap {
  width: 100%;
}

#BlogFeatured header {
  display: flex;
  font-size: 48px;
  align-items: center;
}

#BlogFeatured .logo {
  margin-right: 20px;
}


#BlogFeatured .product-name {
  font-weight: 600;
  min-width: 500px;
}

#BlogFeatured .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#BlogFeatured .icon-wrap {
  position: absolute;
  right: 71px;
  top: 58px;
  z-index: 1;
}

#BlogFeatured .upload-wrap {
  position: absolute;
  padding-top: 130px;
  bottom: 0;
  right: 0;
  z-index: 1 ;
  display: flex;
  height: 100%;
}

#BlogFeatured.layout-5 .upload-wrap {
  width: 50%;
}

#BlogFeatured.layout-6 .upload-wrap {
  width: calc(1/3*100%);
}

#BlogFeatured .upload-wrap .image-upload {
  border-radius: 40px 0 0;
}

/*Products fixes*/
.DN #BlogFeatured .product-name::before {
    display: none;
}

#BlogFeatured.newsletter .title{
  font-size: 160px;
  line-height: 192px;
}

#BlogFeatured.newsletter .date {
  border: 1px solid #fff;
  border-radius: calc( 2 * var(--date-input-border-radius));
  color: #fff;
  display: inline-block;
  font-size: calc(var(--date-input-font-size) * 2);
  font-weight: 400;
  margin-bottom: var(--date-input-margin-bottom);
  padding-top: 8px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 12px;
  width: auto;
  z-index: 100;
  margin-top: 53px;
  margin-left: 20px;
  position: relative;
}

#BlogFeatured.newsletter::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.JB #BlogFeatured.newsletter::after {
  background-image: var(--jb);
  height: 800px;
  right: -116px;
  top: 459px;
  width: 988px;
  z-index: 3;
}

.JB #BlogFeatured.newsletter .textarea {
  /*margin-top: -42px;*/
}


.IJ #BlogFeatured.newsletter::after {
  background-image: var(--java);
  left: 1592px;
  top: 230px;
  width: 769px;
  height: 974px;
  z-index: 3;
}

.DN #BlogFeatured.newsletter .product-name {
  display: none;
}
.DN #BlogFeatured.newsletter::after {
  background-image: var(--dn);
  left: calc(2 * 790px);
  top: calc(2 * 310px);
  width: calc(2 * 356px);
  height: calc(2 * 288px);
  z-index: 3;
}


/*MP*/

.MP #BlogFeatured.newsletter .product-name {
  display: none;
}
.MP #BlogFeatured.newsletter::after {
  background-image: var(--mp);
  width: calc(2* 369px);
  height: calc(2* 450px);
  left: calc(2 * 851px);
  top: calc(2* 264px);
  z-index: 3;
}

/*PS*/

.PS #BlogFeatured.newsletter .product-name {
  display: none;
}
.PS #BlogFeatured.newsletter::after {
  background-image: var(--ps);
  left: calc(2 * 718px);
  top: calc(2 * 269px);
  width: calc(2 * 459px);
  height: calc(2 * 337px);
  z-index: 3;
}

/*TC*/

.TC #BlogFeatured.newsletter .product-name {
  display: none;
}
.TC #BlogFeatured.newsletter::after {
  background-image: var(--tc);
  width: calc(2* 389px);
  height: calc(2* 489px);
  left: calc(2* 819px);
  top: calc(2* 352px);
  z-index: 3;
}

