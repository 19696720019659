
#YouTubeCommunity .product-name {
  font-weight: 600;
  min-width: 500px;
  margin-top: 16px;
  color: #ffffff;
  font-size: 48px;
}

#YouTubeCommunity .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}

#YouTubeCommunity.layout-5 .product-name::before,
#YouTubeCommunity.layout-6 .product-name::before {
  content: "";
  margin: 0 0 0 90px;
  position: relative;
}

#YouTubeCommunity.template {

  height: 1080px;
  width: 1080px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#YouTubeCommunity .dropzone {
  width: 100% ;
}

#YouTubeCommunity .content {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 1080px;
  width: 1080px;
  align-items: flex-start;
  padding: 90px 96px;
}

#YouTubeCommunity.layout-2 .content,
#YouTubeCommunity.layout-3 .content,
#YouTubeCommunity.layout-4 .content,
#YouTubeCommunity.layout-5 .content,
#YouTubeCommunity.layout-6 .content {
    padding: 90px 96px;
}

#YouTubeCommunity.layout-7 .content
{
  padding: 90px 96px;
}

#YouTubeCommunity.layout-3 .subtitle {
  font-size: 60px;
  font-weight: 400;
}

#YouTubeCommunity header {
  display: flex;
  /*align-items: center;*/
  width: 100%;
  font-size: 48px;
}

#YouTubeCommunity.layout-5 header,
#YouTubeCommunity.layout-6 header {
  flex-direction: column;
  align-items: flex-start;
}

#YouTubeCommunity .logo {
  margin-right: 20px;
  display: flex;
  align-items: center;
  width: 327px;
}

#YouTubeCommunity .logo.ide {
  width: 454px;
  margin-top: 9px;
}


.EDU #YouTubeCommunity .logo {
    width: 556px;
}

/*Products fixes*/
.DN #YouTubeCommunity .product-name::before {
    display: none;
}



#YouTubeCommunity .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 48px;
  width: 100%;
}

#YouTubeCommunity.layout-4 .subtitle {
  font-size: 60px;
  line-height: 72px;
}

#YouTubeCommunity.layout-1 .title {
  font-size: 120px;
}

#YouTubeCommunity .title {
  font-weight: 600;
}


#YouTubeCommunity.layout-2 .title {
  font-size: 96px;
}

#YouTubeCommunity.layout-3 .title {
  font-size: 120px;
  margin-bottom: 40px;
}

#YouTubeCommunity.layout-4 .title {
  font-size: 96px;
  margin-bottom: 24px;
}

#YouTubeCommunity.layout-5 .title {
  font-size: 96px;
}

#YouTubeCommunity.layout-6 .title {
  font-size: 96px;
}

#YouTubeCommunity.layout-7 .title {
  font-size: 120px;
}



#YouTubeCommunity .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1 ;
  display: flex;
  border-radius: 20px 0 0;
  height: 810px;
}

#YouTubeCommunity.layout-5 .upload-wrap {
  width: 600px;
}

#YouTubeCommunity.layout-6 .upload-wrap {
  width: 500px;
}

#YouTubeCommunity .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}

#YouTubeCommunity.newsletter .title{
  font-size: 120px;
}

#YouTubeCommunity.newsletter .date {
  border: 1px solid #fff;
  border-radius: var(--date-input-border-radius);
  color: #fff;
  display: inline-block;
  font-size: var(--date-input-font-size);
  font-weight: 400;
  margin-bottom: var(--date-input-margin-bottom);
  padding-top: 4px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 6px;
  width: auto;
  z-index: 100;
  margin-top: 50px;
  position: relative;
}

#YouTubeCommunity.newsletter::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
}

.JB #YouTubeCommunity.newsletter::after {
  background-image: var(--jb);
  height: 400px;
  left: 528px;
  top: 593px;
  width: 489px;
  z-index: 3;
}

.JB #YouTubeCommunity.newsletter .textarea {
  /*margin-top: -21px;*/
}


.IJ #YouTubeCommunity.newsletter .product-name
{
  display: none;
}

.IJ #YouTubeCommunity.newsletter::after {
  background-image: var(--java);
  left: 633px;
  top: 494px;
  width: 386px;
  height: 488px;
  z-index: 3;
}

.DN #YouTubeCommunity.newsletter .product-name {
  display: none;
}
.DN #YouTubeCommunity.newsletter::after {
  background-image: var(--dn);
  left: 608px;
  top: 675px;
  width: 371px;
  height: 300px;
  z-index: 3;
}


/*MP*/

.MP #YouTubeCommunity.newsletter .product-name {
  display: none;
}
.MP #YouTubeCommunity.newsletter::after {
  background-image: var(--mp);
  width: 409px;
  height: 489px;
  left: 629px;
  top: 563px;
  z-index: 3;
}

/*PS*/

.PS #YouTubeCommunity.newsletter .product-name {
  display: none;
}
.PS #YouTubeCommunity.newsletter::after {
  background-image: var(--ps);
  left: 525px;
  top: 631px;
  width: 488px;
  height: 351px;
  z-index: 3;
}


/*TC*/

.TC #YouTubeCommunity.newsletter .product-name {
  display: none;
}
.TC #YouTubeCommunity.newsletter::after {
  background-image: var(--tc);
  width: 409px;
  height: 489px;
  left: 607px;
  top: 563px;
  z-index: 3;
}