.template-controls {
    display: flex;
    flex-direction: row;
}

.template-annotation {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 7px;
}


.template-selector {
    margin-bottom: 40px;
    padding: 13px 6px;
    border: 1px solid #646464;
    background-color: #27282C;
    border-radius: 6px;
}


.template-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;
}

.template-checkbox {
    display: none;
}

.template-option .checkbox {
    width: 30px;
    cursor: pointer;
}

.template-option .checkbox img {
    cursor: pointer;
}

.template-option .template-label {
    flex-grow: 5;
    color: #DFDFDF;
    font-size: 14px;
}

.checkbox.lock {
    opacity: 0.5;
    text-align: right;
}

.checkbox.lock img {
    height: 20px;
}

.checkbox.eye {
    padding: 8px;
    margin-right: 5px;
}

.checkbox.eye img{
    width: 100%;
}
.checkbox.lock.locked {
    opacity: 1;
}

.checkbox {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}