
#TwitterLivestream.template {
  height: 675px;
  width: 1200px;
}

#TwitterLivestream .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px;
  height: 675px;
  width: 100%;
  position: relative;
}


#TwitterLivestream  .title {
  color: #ffffff;
  font-weight: 500;
  width: 630px;
  font-size: 80px;
}

#TwitterLivestream  .name {
  color: #ffffff;
  width: 630px;
  font-size: 40px;
  font-weight: 600;
}


#TwitterLivestream header {
  display: flex;
  font-size: 48px;
  align-items: center;
}



#TwitterLivestream .logo {
  margin-right: 20px;
  width: 335px;
}

#TwitterLivestream .logo.ide {
  width: 378px;
}


#TwitterLivestream .product-name {
  font-weight: 600;
  /*min-width: 500px;*/
  /*margin-top: 10px;*/
  font-size: 40px;
}

#TwitterLivestream .product-name::before {
  content: "|";
  margin: 0 14px 0 0;
  position: relative;
}


#TwitterLivestream .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#TwitterLivestream .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1 ;
  display: flex;
  border-radius: 20px 0 0;
  height: 100%;
}


#TwitterLivestream .upload-wrap .image-upload {
  border-radius: 20px 0 0;
}


#TwitterLivestream .badge {
  background-color: #000 !important;
  border-radius: 30px;
  color: #fff;
  content: "Livestream";
  display: block;
  font-size: 40px;
  font-weight: 600;
  height: 50px;
  line-height: 45px;
  margin-top: 0;
  order: 3;
  padding: 0 30px 0 36px;
  position: absolute;
  right: 48px;
  z-index: 10;
  width: fit-content;
}

.EDU #TwitterLivestream .logo {
  width: 570px;
  margin-top: -2px;
}

.EDU #TwitterLivestream .badge {
  margin-top: 12px;
}

.EDU #TwitterLivestream .logo {
  width: 570px;
  margin-top: -2px;
}

.EDU #TwitterLivestream .title {
  font-size: 80px;
  width: 720px;
  margin-top: -20px;
}