
#BlogSocialShareInternal.template {
  height: calc(2 * 720px);
  width: calc(2 * 1280px);
}

#BlogSocialShareInternal .content {
  background-position: 0 -500px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 160px 192px 280px;
  height: calc(2 * 720px);
  width: 100%;
}

#BlogSocialShareInternal.layout-5 .content,
#BlogSocialShareInternal.layout-6 .content {
  padding: 120px 144px;
}

#BlogSocialShareInternal.layout-5 .title-wrap {
  width: 50%;
  padding-right: 20px;
}

#BlogSocialShareInternal.layout-6 .content {
  width: 70%;
  padding-right: 20px;
}

#BlogSocialShareInternal.layout-7 .content {
  width: 80%;
  padding-right: 20px;
}

#BlogSocialShareInternal  .title {
  color: #ffffff;
  font-weight: 600;
}

#BlogSocialShareInternal  .subtitle {
  color: #ffffff;
  font-weight: 400;
  font-size: 80px;
  width: 100%;
}

#BlogSocialShareInternal.layout-1 .title {
  font-size: 192px;
}

#BlogSocialShareInternal.layout-2  .title{
  font-size: 144px;
}

#BlogSocialShareInternal.layout-3 .title {
  font-size: 192px;
  margin-bottom: 24px;
}

#BlogSocialShareInternal.layout-4 .title {
  font-size: 144px;
  margin-bottom: 50px;
}

#BlogSocialShareInternal.layout-5 .title {
  font-size: 144px;
}

#BlogSocialShareInternal.layout-6 .title {
  font-size: 144px;
}

#BlogSocialShareInternal.layout-7 .title {
  font-size: 192px;
}

#BlogSocialShareInternal.layout-5  header,
#BlogSocialShareInternal.layout-6  header {
  flex-direction: column;
  align-items: flex-start;
}

#BlogSocialShareInternal header {
  display: flex;
  font-size: 48px;
  align-items: center;
}


#BlogSocialShareInternal .logo {
  margin-right: 20px;
  width: 683px;
}

#BlogSocialShareInternal .logo.ide {
  width: 910px;
}


.EDU #BlogSocialShareInternal .logo {
  width: 910px;
}


#BlogSocialShareInternal .product-name {
  font-weight: 600;
  min-width: 500px;
  font-size: 96px;
  margin-top: 12px;
}
#BlogSocialShareInternal.layout-5 .product-name,
#BlogSocialShareInternal.layout-5 .product-name {
  margin-left: 172px;
}
.blacklogo #BlogSocialShareInternal .product-name {
  color: #000;
}

#BlogSocialShareInternal .product-name::before {
  content: "|";
  margin: 0 20px 0 0;
  position: relative;
}

#BlogSocialShareInternal.layout-5 .product-name::before,
#BlogSocialShareInternal.layout-6 .product-name::before {
  content: "";
  display: none;
}

#BlogSocialShareInternal .icon-wrap {
  position: absolute;
  right: 71px;
  bottom: 58px;
  z-index: 1;
}

#BlogSocialShareInternal .upload-wrap {
  position: absolute;
  padding-top: 65px;
  bottom: 0;
  right: 0;
  z-index: 1 ;
  display: flex;
  height: 100%;
}

#BlogSocialShareInternal.layout-5 .upload-wrap {
  width: 50%;
}

#BlogSocialShareInternal.layout-6 .upload-wrap {
  width: calc(1/3*100%);
}

#BlogSocialShareInternal .upload-wrap .image-upload {
  border-radius: 40px 0 0;
}

#BlogSocialShareInternal .title-wrap {
}